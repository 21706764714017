@import '../../constants/constants';

#landing-section-container {
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;

  #landing-inner-container {
    width: 900px;

    @media (max-width: 920px) {
      width: 50%;
    }
    @media (max-width: 965px) {
      width: 85%;
    }
    @media (max-width: 700px) {
      width: 95%;
    }

    h1 {
      position: relative;
      font-family: 'Vollkorn', serif;
      color: $themeRed;
      font-size: 100px;
      margin: 0;

      @media (max-width: 950px) {
        font-size: 80px;
      }

      @media (max-width: 770px) {
        font-size: 70px;
      }

      @media (max-width: 600px) {
        margin-left: 10px;
        font-size: 60px;
      }

      @media (max-width: 530px) {
        font-size: 50px;
      }

      @media (max-width: 455px) {
        font-size: 40px;
      }
    }
    h2 {
      position: relative;
      margin-top: 5px;
      color: $themeLightYellow;
      font-family: 'Archivo', sans-serif;
      font-size: 60px;
      font-weight: 600;

      @media (max-width: 950px) {
        font-size: 50px;
      }

      @media (max-width: 770px) {
        font-size: 40px;
      }

      @media (max-width: 600px) {
        margin-left: 10px;
        font-size: 40px;
      }

      @media (max-width: 530px) {
        font-size: 30px;
      }
    }
  }
}
