@import '../../constants/constants';

#navContainer {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: $themeRed;
  z-index: 2;

  .nav-item {
    font-family: 'Roboto Mono', monospace;
    list-style: none;
    padding: 15px;
    cursor: pointer;
    font-size: 18px;
    color: $themeLightYellow;

    &:hover,
    &:focus {
      color: $themeYellowHover;
    }

    @media (max-width: 425px) {
      font-size: 12px;
    }
  }

  #nav-email {
    position: absolute;
    right: 5px;
    font-family: 'Roboto Mono', monospace;
    list-style: none;
    padding: 15px;
    cursor: pointer;
    font-size: 18px;
    color: $themeLightYellow;

    &:hover,
    &:focus {
      color: $themeYellowHover;
    }

    @media (max-width: 725px) {
      display: none;
    }
  }
}
