@import '../../constants/constants';

#social-list {
  @include center;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;

  &:before {
    content: '';
    display: block;
    width: 2px;
    height: 100vh;
    margin: 0 auto;
    background-color: $themeWhite;
  }

  &:after {
    content: '';
    display: block;
    width: 2px;
    height: 90px;
    margin: 0 auto;
    background-color: $themeWhite;
  }
  li {
    padding: 10px;
    &:first-child {
      margin-top: 20px;
    }
    &:last-child {
      margin-bottom: 20px;
    }
    a {
      i {
        color: white;
        font-size: 24px;
        &:hover,
        &:focus {
          transform: translateY(-3px);
        }
      }
    }
  }
}
