@import '../../constants/constants';

#outer-about-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  #about-main-container {
    width: 900px;

    @media (max-width: 920px) {
      width: 50%;
    }
    @media (max-width: 965px) {
      width: 85%;
    }
    @media (max-width: 700px) {
      width: 95%;
    }

    #about-title-container {
      display: flex;
      flex-direction: row;
      text-align: center;

      &:before {
        content: '';
        margin-top: 30px;
        margin-right: 10px;
        display: block;
        width: 20%;
        height: 2px;
        background-color: $themeWhite;
      }

      &:after {
        content: '';
        margin-top: 30px;
        margin-left: 10px;
        display: block;
        width: 70%;
        height: 2px;
        background-color: $themeWhite;
      }

      #about-title {
        @include section-header-red;
      }
    }

    #about-column-wrapper {
      display: flex;
      flex-direction: row;
      background-color: rgba(18, 18, 18, 0.9);

      @media (max-width: 600px) {
        flex-direction: column;
      }

      #about-column-1 {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media (max-width: 600px) {
          width: 100%;
        }

        p {
          @include paragraph-gray-text;

          a {
            color: $themeRed;
          }
        }

        #about-column-1-header {
          color: $themeRed;
          margin-left: 10px;
          margin-right: 10px;
          font-size: 17px;
          line-height: 25px;
          font-family: 'Cairo', sans-serif;
        }
      }

      #about-column-2 {
        text-align: center;
        width: 43%;
        display: flex;
        flex-direction: column;
        background-color: rgba(18, 18, 18, 0.9);

        @media (max-width: 600px) {
          width: 100%;
        }

        h1 {
          color: $themeRed;
          margin-left: 10px;
          margin-right: 10px;
          font-size: 17px;
          line-height: 25px;
          font-family: 'Cairo', sans-serif;
        }

        #about-tech-grid {
          display: grid;

          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(4, 1fr);

          i {
            color: white;
            font-size: 32px;
            margin-bottom: 7px;
          }

          #sql {
            margin-left: 35px;
            width: 64px;
            filter: brightness(0) invert(1);
          }

          #firebase {
            width: 30px;
            margin-left: 50px;
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }

  #about-button-container {
    width: 900px;
    display: flex;
    flex-direction: row;
    background-color: rgba(15, 15, 15, 0.9);

    @media (max-width: 920px) {
      width: 50%;
    }
    @media (max-width: 965px) {
      width: 85%;
    }
    @media (max-width: 700px) {
      width: 95%;
    }

    button {
      border-radius: 3px;
      cursor: pointer;
      color: $themeWhite;
      font-family: 'Roboto Mono', monospace;
      font-size: 16px;
      font-weight: 200;
      padding: 17px;
      background-color: $themeRed;
      border: none;
      outline: none;
      margin: 30px 10px 15px 15px;

      @media (max-width: 710px) {
        padding: 15px;
        font-size: 15px;
      }

      &:hover {
        background-color: $themeRedHover;
      }
    }
  }
}
