@import '../../constants/constants';

#main-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 150px;
  text-align: center;

  .footer-text {
    color: rgb(138, 138, 138);
    font-size: 17px;
    font-family: 'Cairo', sans-serif;
  }
}
