@import '../../constants/constants.scss';

#outer-experience-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 325px;

  #experience-main-container {
    width: 900px;

    @media (max-width: 920px) {
      width: 50%;
    }
    @media (max-width: 965px) {
      width: 85%;
    }
    @media (max-width: 700px) {
      width: 95%;
    }

    #experience-header-container {
      display: flex;
      flex-direction: row;
      text-align: center;

      &:before {
        content: '';
        margin-top: 30px;
        margin-right: 10px;
        display: block;
        width: 20%;
        height: 2px;
        background-color: $themeWhite;
      }

      &:after {
        content: '';
        margin-top: 30px;
        margin-left: 10px;
        display: block;
        width: 70%;
        height: 2px;
        background-color: $themeWhite;
      }

      #experience-header {
        @include section-header-red;
      }
    }

    #job-selection-column {
      margin-top: 15px;
      background-color: rgba(15, 15, 15, 0.9);

      @media (max-width: 954px) {
        display: none;
      }

      ul {
        margin: 0;
        padding: 0;
      }

      li {
        display: inline;
        text-align: center;
        white-space: nowrap;
      }

      hr {
        margin: 0;
        height: 0.25rem;
        width: 33.33%;
        background: $themeYellow;
        border: none;
        transition: 0.3s ease-in-out;
      }

      #selected-index-1 {
        margin-left: 33.33%;
      }

      #selected-index-2 {
        margin-left: 66.66%;
      }

      #job-title-container-true {
        @include paragraph-gray-text;
        margin: 0;
        box-sizing: border-box;
        height: 20px;
        cursor: pointer;

        #employer-text {
          margin: 0;
          font-size: 1.3rem;
          display: inline-block;
          width: 33.33%;
          text-decoration: none;
          padding: 0.75rem 0;
        }
      }

      #job-title-container-false {
        @include paragraph-gray-text;
        margin: 0;
        background-color: rgba(15, 15, 15, 0.9);
        height: 20px;
        cursor: pointer;

        #employer-text {
          margin: 0;
          display: inline-block;
          font-size: 1.3rem;
          width: 33.33%;
          padding: 0.75rem 0;
          text-decoration: none;
        }
      }
    }

    #experience-column {
      display: flex;
      flex-direction: column;
      padding: 0px 25px 0px 25px;
      min-height: 450px;
      background-color: rgba(18, 18, 18, 0.9);

      h1 {
        color: $themeRed;
        margin: 0;
        margin-top: 15px;
        font-size: 25px;
        font-family: 'Cairo', sans-serif;
      }

      h2 {
        margin: 0;
        color: $themeYellow;
        font-size: 17px;
        font-family: 'Cairo', sans-serif;
      }

      p {
        @include paragraph-gray-text;
        margin: 10px 10px 10px 10px;
      }
    }

    #experience-inner-container {
      background-color: rgba(18, 18, 18, 0.9);
      width: 100%;

      @media (min-width: 955px) {
        display: none;
      }

      #job-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        #job-image {
          width: 400px;
          height: auto;

          @media (max-width: 420px) {
            width: 99%;
          }
        }

        h1 {
          margin: 10px 10px 10px 10px;
          color: $themeRed;
          font-size: 19px;
          font-family: 'Cairo', sans-serif;
        }

        h2 {
          margin: 10px 10px 10px 10px;
          color: $themeYellow;
          font-size: 17px;
          line-height: 25px;
          font-family: 'Cairo', sans-serif;
        }

        h3 {
          @include paragraph-gray-text;
        }

        #job-paragraph-container {
          p {
            @include paragraph-gray-text;
            margin: 10px 10px 10px 10px;
          }

          :last-child {
            margin-bottom: 45px;
          }
        }
      }

      #column-container {
        #job-container {
          display: flex;
          flex-direction: column;
          margin-left: 15px;

          p {
            @include paragraph-gray-text;
            margin: 10px 10px 10px 10px;
          }
        }
      }
    }
  }
}
